import logo from './logo.svg';

import feather from 'feather-icons';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Link } from 'react-router-dom';


import token1 from './assets/theme_asset/token/21.png';
import token2 from './assets/theme_asset/token/22.png';
import token3 from './assets/theme_asset/token/23.png';
import token4 from './assets/theme_asset/token/24.png';
import token5 from './assets/theme_asset/token/25.png';
import token6 from './assets/theme_asset/token/26.png';
import token7 from './assets/theme_asset/token/27.png';
import token8 from './assets/theme_asset/token/28.png';
import token9 from './assets/theme_asset/token/29.png';
import token10 from './assets/theme_asset/token/30.png';
import color1 from './assets/theme_asset/token/color1.png';
import color2 from './assets/theme_asset/token/color2.png';
import color3 from './assets/theme_asset/token/color3.png';

import back from './assets/theme_asset/back.png';

import { BiSolidLeftArrow } from 'react-icons/bi';

import { useLocation } from 'react-router-dom';



import './game.css';

import { useEffect } from 'react';

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';






function Game5() {

  const navigate = useNavigate();

  const [showPopup, setShowPopup] = useState(false);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [amount, setAmount] = useState('');

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  
  const [selectedToken, setSelectedToken] = useState(null);

  const [quantity, setQuantity] = useState(1); // State for quantity
  const [selectedTab, setSelectedTab] = useState('Transaction');

  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  const [totalAmount1, setTotalAmount1] = useState(0);
  const location = useLocation();


  const [selectedTokenBackground, setSelectedTokenBackground] = useState('');

  const handleTokenClick = (token) => {
    setSelectedToken(token);
    setSelectedColor(null);
    setselectedcondition(null); // Reset selectedColor when a token is clicked
    setShowPopup(true);
    setSelectedItem(token);

  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  
 
const fetchData = async () => {
  try {
    const id = localStorage.getItem('id');
    const response = await axios.get(`${apiBaseUrl}/gamepageapi/${id}`);
    setData(response.data);

    console.log(response.data);

    const totalAmount1 = response.data.wallet.wallet_balance;

    const  startTime  = response.data.slot5.start_time;
    const  endTime  = response.data.slot5.end_time;


    const  serverTime  = new Date(response.data.serverTime).getTime();

  
    var currentTime = new Date().getTime(); // Current time in milliseconds

    const ofsetTime =  serverTime - currentTime;

    console.log(ofsetTime);

    currentTime = currentTime + ofsetTime;


    // Calculate time remaining for the game
    const timeDiff = new Date(endTime).getTime() - currentTime;
    setTimeRemaining(timeDiff > 0 ? timeDiff : 0);

    setTotalAmount1(totalAmount1);

    const { color, token } = response.data;
    // Set the selected item based on color or token
    setSelectedItem(color || token);
    
  } catch (error) {
    setError(error);
  } finally {
    setLoading(false);
  }
};
  const handleFormSubmit = async (e, totalAmount1) => {
    e.preventDefault();
    try {
      const amount = e.target.amount.value;
      const id = e.target.id.value;
      const slot_id = e.target.slot_id.value;
      const reason = e.target.reason.value;
      const quantity = e.target.quantity.value;
      const inputcode = e.target.inputcode.value;
  
      console.log('FormData to send:', amount, id, slot_id,reason, quantity, inputcode);
  
      const response = await axios.post(`${apiBaseUrl}/playgame`, {
        amount,
        id,
        quantity,
        slot_id,
        reason,
        inputcode,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      console.log(totalAmount1);
  
      if (totalAmount1 < totalAmount) {
        setModalMessage({
          title: 'Insufficient Balance',
          content: 'To play the game, please recharge your amount.',
        });
        setShowModal(true);
        
        // Prevent form submission if condition is true
        return;
      }
  
      console.log('User updated successfully:', response.data);
      handleClosePopup();
      navigate('/game5');
    } catch (error) {
      console.error('Error updating user:', error);
      // Handle error, show error message, etc.
    }
  };
  

  const closeModal = () => {
    setShowModal(false);
  };
  

  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedcondition, setselectedcondition] = useState(null);

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setSelectedToken(null);
    setselectedcondition(null);
    setShowPopup(true);
    setSelectedItem(color);// Update selected item with token

  };
      

 
  

  const [selectedItem, setSelectedItem] = useState(''); // Combined selected color/token


  const [timeRemaining, setTimeRemaining] = useState(0);

  // Fetch slot data from backend API
 

     // console.log(apiBaseUrl);

     useEffect(() => {
        const handleVisibilityChange = () => {
          if (document.visibilityState === 'visible') {
            fetchData();
          }
        };
      
        // Add event listener for visibility change
        document.addEventListener('visibilitychange', handleVisibilityChange);
      
        // Fetch data initially if the tab is already visible
        if (document.visibilityState === 'visible') {
          fetchData();
        }
      
        // Cleanup event listener
        return () => {
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
      }, []);

    
    
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeRemaining(prevTime => {
          const newTime = prevTime > 0 ? prevTime - 1000 : 0;
          if (newTime === 0) {
            // If time reaches zero, refresh the page
            fetchData();
          }
          return newTime;
        });
      }, 1000);
    
      // Cleanup timer
      return () => clearInterval(timer);
    }, [timeRemaining]);
    
    const formatTime = () => {
      const minutes = Math.floor(timeRemaining / 60000);
      const seconds = Math.floor((timeRemaining % 60000) / 1000);
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };
    
    const handleChange = (e) => {
      const { name, value, files } = e.target;
  
      if (name === 'amount') setAmount(value);
    };
    
    
  const [totalAmount, setTotalAmount] = useState(0);


  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  
  const handleAmountButtonClick = (value) => {
    setAmount(parseInt(value, 10)); // Parse the value to an integer
};


  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value, 10); // Parse the input value to an integer
    setQuantity(newQuantity);
};

  const getBackgroundColor = () => {

   
    // Define the mapping of tokens to colors
    const tokenColorMap = {
        0: '#35b276',
        1: '#f22929',
        2: '#35b276',
        3: '#f22929',
        4: '#35b276',
        5: '#f22929',
        6: '#35b276',
        7: '#f22929',
        8: '#35b276',
        9: '#f22929',
        'red': '#f30606',
        'blue': '#3498db',
        'green': '#53d348',
        'big' : '#3498db',
        'small' : '#53d348',
      };
  
      // Check if the selected token is in the mapping
      if (selectedToken !== null && tokenColorMap[selectedToken]) {
        return tokenColorMap[selectedToken];
      } else if (selectedColor !== null && tokenColorMap[selectedColor]) {
        return tokenColorMap[selectedColor];
      } else if (selectedcondition !== null && tokenColorMap[selectedcondition]) {
        return tokenColorMap[selectedcondition];
      }else {
        return '#FFFFFF'; // Return default color if neither token nor color is selected
      }
};


 
  
  

    const getImageForSelectedValue = (no) => {
      switch(no) {
        case '0':
          return token1;
        case '1':
          return token2;
        case '2':
          return token3;
        case '3':
          return token4;
        case '4':
          return token5;
        case '5':
          return token6;
        case '6':
          return token7;
        case '7':
          return token8;
        case '8':
          return token9;
        case '9':
          return token10;
        case 'red':
          return color2;
        case 'blue':
          return color1;
        case 'green':
          return color3;

        // Add cases for other values up to 12
        default:
          return null; // Return null if no image matches the selected value
      }
    }
    const getImageForSelectedValue1 = (SelectedValue) => {
        switch(SelectedValue) {
          case '0':
            return token1;
          case '1':
            return token2;
          case '2':
            return token3;
          case '3':
            return token4;
          case '4':
            return token5;
          case '5':
            return token6;
          case '6':
            return token7;
          case '7':
            return token8;
          case '8':
            return token9;
          case '9':
            return token10;
          case 'red':
            return color2;
          case 'blue':
            return color1;
          case 'green':
            return color3;
          case 'big' :
            return token1;
          case'small' :
            return token2;
  
          // Add cases for other values up to 12
          default:
            return null; // Return null if no image matches the selected value
        }
      }


    const [showPopup30Sec, setShowPopup30Sec] = useState(false);

// Effect to monitor time remaining and show popup when time reaches 30 seconds
useEffect(() => {
  // Check if time remaining is less than 30000
  if (timeRemaining < 31000) {
    setShowPopup30Sec(true);
    handleClosePopup();
    // After 30 seconds, hide the popup
    const popupTimer = setTimeout(() => {
      setShowPopup30Sec(false);
    }, 30000 - timeRemaining); // Adjust timeout based on remaining time

    // Cleanup timer
    return () => clearTimeout(popupTimer);
  } else {
    // If time remaining is not less than 30000, hide the popup
    setShowPopup30Sec(false);
  }
}, [timeRemaining]);


useEffect(() => {
    // Replace icons after component is mounted
    

      
       
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/game5');
    }
    console.log(token)
    feather.replace();

    setTotalAmount(quantity * amount);
  }, [quantity, amount]);

 

  if (loading) {
    
      <SkeletonLoader />
    
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data == null)
    {
      return (
        <SkeletonLoader showHeader={false} showFooter={false} /> 

       )
    }

    return (
      <body className='dark'>
      <div >
          {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
        <div className={`background-container ${showPopup ? 'blur-background' : ''}`} >
          <div className="auth-header-1" style={{height:'40px'}}>
          <div className="row align-items-center">
  <div className="col-1">
    <Link to="/" style={{ textDecoration: 'none', marginLeft:'15px' }}>
    <BiSolidLeftArrow style={{ color: 'white' }} />
    </Link>
  </div>
  <div className='col-4'></div>
  <div className="col-3 p-1 ">
  <h2 style={{marginTop:'5px'}}>BBNPLAY</h2>

  </div>
</div>
      </div>
       

      <div className="title" style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-around' }}>
      <Link to='/game1'>
        <h2 style={{ cursor: 'pointer', backgroundColor: location.pathname === '/game1' ? 'goldenrod' : 'transparent' }}>1 Minute</h2>
      </Link>
      <Link to='/game'>
        <h2 style={{ cursor: 'pointer', backgroundColor: location.pathname === '/game' ? 'goldenrod' : 'transparent' }}>3 Minute</h2>
      </Link>
      <Link to='/game5'>
        <h2 style={{ cursor: 'pointer', backgroundColor: location.pathname === '/game5' ? 'goldenrod' : 'transparent' }}>5 Minute</h2>
      </Link>
    </div>
       {/* <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/iconsax@1.1.5/css/iconsax.css" integrity="sha384-kJ8Bl2nzBRn1Fls+eWnBEqMWvAqNSMIAd1N2fXk4UuI2V5kyAFLtk4WuIqHg4HS7" crossOrigin="anonymous" /> */}
       
       
       <section>
        <div>
        <div className="custom-container">
        <div className="game-card">
      <div className="game-info">
        <h2 className="time">Starts in: <span>{formatTime()}</span></h2>
        <p className="color">Predict the Color</p>
      </div>
    </div></div>
        </div>
       </section>


      

       <section style={{backgroundColor:'#ffc0cb63', position: 'relative',borderRadius:'25px'}}>
  
    <div>
      <div className='custom-container'>
        <div className='token-container1'>
          <div className='token-data1 '>
            <div className='card'>
              <button
                className={`token-button ${selectedColor === 'blue' ? 'rotate' : ''}`}
                onClick={() => handleColorSelect('blue')}
              >Blue</button>
            </div>
            <div className='card'>
              <button
                className={`token-button1 ${selectedColor === 'red' ? 'rotate' : ''}`}
                onClick={() => handleColorSelect('red')}
              >Red</button>
            </div>
            <div className='card'>
              <button
                className={`token-button2 ${selectedColor === 'green' ? 'rotate' : ''}`}
                onClick={() => handleColorSelect('green')}
              >Green</button>
            </div>
          </div>
        </div>
      </div>
   
      <div className='custom-container'>
        <div className='token-container'>
          <div className='row_np row-cols-5'>
            <img
              className={`col token-img ${selectedToken === 'token1' ? 'rotate' : ''}`}
              src={token1}
              alt='token1'
              onClick={() => handleTokenClick('0')}
            />
            <img
              className={`col token-img ${selectedToken === 'token2' ? 'rotate' : ''}`}
              src={token2}
              alt='token2'
              onClick={() => handleTokenClick('1')}
            />
            <img
              className={`col token-img ${selectedToken === 'token3' ? 'rotate' : ''}`}
              src={token3}
              alt='token3'
              onClick={() => handleTokenClick('2')}
            />
            <img
              className={`col token-img ${selectedToken === 'token4' ? 'rotate' : ''}`}
              src={token4}
              alt='token4'
              onClick={() => handleTokenClick('3')}
            />
            <img
              className={`col token-img ${selectedToken === 'token5' ? 'rotate' : ''}`}
              src={token5}
              alt='token5'
              onClick={() => handleTokenClick('4')}
            />
          </div>
          
          <div className='row_np row-cols-5'>
            <img
              className={`col token-img ${selectedToken === 'token6' ? 'rotate' : ''}`}
              src={token6}
              alt='token6'
              onClick={() => handleTokenClick('5')}
            />
            <img
              className={`col token-img ${selectedToken === 'token7' ? 'rotate' : ''}`}
              src={token7}
              alt='token7'
              onClick={() => handleTokenClick('6')}
            />
            <img
              className={`col token-img ${selectedToken === 'token8' ? 'rotate' : ''}`}
              src={token8}
              alt='token8'
              onClick={() => handleTokenClick('7')}
            />
            <img
              className={`col token-img ${selectedToken === 'token9' ? 'rotate' : ''}`}
              src={token9}
              alt='token9'
              onClick={() => handleTokenClick('8')}
            />
            <img
              className={`col token-img ${selectedToken === 'token10' ? 'rotate' : ''}`}
              src={token10}
              alt='token10'
              onClick={() => handleTokenClick('9')}
            />
          </div>
        </div>
      </div>
    </div>

    <div className='custom-container'>
        <div className='token-container1'>
          <div className='token-data1 '>
            <div className='card'>
              <button
                className={`token-button ${selectedcondition === 'big' ? 'rotate' : ''}`}
                onClick={() => handleColorSelect('big')}
              >Big</button>
            </div>
           
            <div className='card'>
              <button
                className={`token-button2 ${selectedcondition === 'small' ? 'rotate' : ''}`}
                onClick={() => handleColorSelect('small')}
              >Small</button>
            </div>
          </div>
        </div>
      </div>

  {showPopup30Sec && (
    <div className="custom-popup-container">
      <div className="custom-popup-content">
       
        <div className="custom-countdown-container">
          <div className="custom-countdown-circle">
            <svg>
              <circle cx="25" cy="25" r="24"></circle>
              <text x="50%" y="50%" textAnchor="middle" dy="0.3em">
                {Math.ceil(timeRemaining / 1000)}
              </text>
            </svg>
          </div>
        </div>
      </div>
    </div>
  )}
  {/* Overlay */}
  {showPopup30Sec && <div className="overlay"> </div>}
</section>

    <section><br/>
  <div className="custom-container">
  <div className="title" style={{display:'flex', justifyContent:'space-between' }}>
    <h2 className={selectedTab === 'Transaction' ? 'active' : ''} onClick={() => setSelectedTab('Transaction')} style={{ cursor: 'pointer' }}>Transaction</h2>
    <h2 className={selectedTab === 'MyOrder' ? 'active' : ''} onClick={() => setSelectedTab('MyOrder')} style={{ cursor: 'pointer' }}>My Order</h2>
</div>

    
    {selectedTab === 'Transaction' && (
        <div>
          
              {data.slotfive && data.slotfive.length > 0 && (
  <section >
  
  <div className="custom-container">
 
      {data.slotfive.map((slotfive, index) => (
        <div className="row ">
        <div className="col-12">
          <div className="transaction-box">
            <a href="#" className="d-flex gap-3">
              <div className="transaction-image">
              <img
                  className="img-fluid"
                  src={getImageForSelectedValue(slotfive.no)}
                  alt="p1"
                />
              </div>
              <div className="transaction-details">
                <div className="transaction-name">
                <h5 className="light-text">{slotfive.hashed_slot_id}</h5>
                  <h5 className='ml-5'> {slotfive.no}</h5>
                  <h5> {slotfive.color}</h5>
                  <h5> {slotfive.bigsmall}</h5>
                </div>
                
              </div>
            </a>
          </div>
        </div>
        </div>
      ))}
    </div>
  </section>
)}

          </div>
      )}
 {selectedTab === 'MyOrder' && (
        <div>
          {data.game_report.gameplay5 && data.game_report.gameplay5.length > 0 && data.game_report.transaction && data.game_report.transaction.length > 0 &&  (
  <section >
  
  <div className="custom-container">
     
      {data.game_report.gameplay5.map((game_report, index) => (
        <div className="row ">
        <div className="col-12">
          <div className="transaction-box">
            <a href="#" className="d-flex gap-3">
              <div className="transaction-image">
                <img
                  className="img-fluid"
                  src={getImageForSelectedValue1(game_report.selected_value)}
                  alt="p1"
                />
              </div>
              <div className="transaction-details">
                <div className="transaction-name">
                {data.game_report.slot[index] && (
                  <h5 className="light-text">{data.game_report.slot[index].hashed_slot_id}</h5> )}
                  <h5>{game_report.selected_value}</h5>
                  {data.game_report.transaction[index] && (
                <h3 className={data.game_report.transaction[index].receipt === 'win' ? 'success-color' : 'error-color'}>
                ${data.game_report.transaction[index].amount}
              </h3> )} </div>
                <div className="d-flex justify-content-between">
               
                <h3 className='light-text'>
  {new Date(game_report.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
</h3>

              
                  {data.game_report.transaction[index] && (
                  <h3 className={data.game_report.transaction[index].receipt === 'win' ? 'success-color' : 'error-color'}>
                    {data.game_report.transaction[index].receipt}
                  </h3>
                )}
                </div>
              </div>
            </a>
          </div>
        </div>
        </div>
      ))}
    </div>
  </section>
)}
        </div>
      )}
    
  </div><br/><br/><br/><br/><br/><br/>
</section>   </div>
{showPopup && (
    
        <div className={`popup-container active`} style={{backgroundColor:'black'}} >
          <div className='row'>
<div className='col-10'>
      <h6 className="mt-3 text-center dark-text">
        Availble Balance : <div className="" style={{fontSize:'20px',color:'white'}}>${data.wallet.wallet_balance}</div>
      </h6>
      <h6 className="mt-3 text-center dark-text">
        Total Amount  : <span className="" style={{color:'white'}}>{totalAmount}</span>
      </h6>
      </div>
      <button onClick={handleClosePopup} style={{height:'30px', width:'30px' ,padding:'0'}} className='btn btn-danger col-2'>X</button>

      </div>
      <form onSubmit={(e) => handleFormSubmit(e, totalAmount1)}>

            <section className="section-b-space">

  <div className="custom-container">
    <div className="currency-transfer">
      
        <div className="form-group">
          <label htmlFor="inputcard" className="form-label mb-2">
            Your selected color/number 
          </label>
          <div className="d-flex gap-2 mb-2">
          <input
              type="readonly"
              className="form-control"
              id="inputcode"
              name="inputcode"
              value={selectedItem}
              placeholder="Enter your IBAN"
              readOnly
            />
          </div>
        </div>
        <div className="form-group">
      <div className='row mb-2'>
        <label className="form-label col-4">Quantity:</label>
        <Link to='#' className="col-2 btn-amount" style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={handleDecrement}>-</Link>
        <input type="number" className="col-2 m-1" name="quantity" id="quantity"  value={quantity} onChange={handleQuantityChange} />
        <Link to='#' className="col-2 btn-amount" style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={handleIncrement}>+</Link>
      </div>
      <div className='row mb-3'>
        <div className='col-2'></div>
        <Link to='#' className='col-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => setQuantity(1)}>x1</Link>
        <Link to='#' className='col-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => setQuantity(10)}>x10</Link>
        <Link to='#' className='col-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => setQuantity(50)}>x50</Link>
        <Link to='#' className='col-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => setQuantity(100)}>x100</Link>
      </div>
    </div>
        <div className="form-group">
          <div className="form-input">
          <div className='row mb-3'>
          <div className='col-3' style={{color:'white'}}> Amount:</div>
          <Link to="#" className='col-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => handleAmountButtonClick('10')}>10</Link>
            <Link to="#" className='col-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => handleAmountButtonClick('20')}>20</Link>
            <Link to="#" className='col-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => handleAmountButtonClick('50')}>50</Link>
            <Link to="#" className='col-2 btn-amount' style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }} onClick={() => handleAmountButtonClick('100')}>100</Link>
         </div>   
         <input
              type="hidden"
              className="form-control"
              id="id"
              name="id"
              placeholder="Enter your Amount"
              value={data.user.id}
            />
             <input
              type="hidden"
              className="form-control"
              id="slot_id"
              name="slot_id"
              placeholder="Enter your Amount"
              value={data.slot1.id}
            />
            <input
              type="hidden"
              className="form-control"
              id="reason"
              name="reason"
              placeholder="Enter your Amount"
              value='five_minute'
            />
            <input
              type="hidden"
              className="form-control"
              id="amount"
              name="amount"
              placeholder="Enter your Amount"
              value={totalAmount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
        </div>
        
        <div className=" row">
        <div className='col-1'></div>
        
        <input
              type="submit"
              className="close-btn col-12"
              style={{ backgroundColor: getBackgroundColor(selectedToken, selectedColor) }}
              id="submit"
              name="submit"
              value="Submit"
            />
         </div>
      
     
   
    </div>
  </div>
</section>
  
</form>

        </div>
      )}
</div>
</body>
  );
  }

  export default Game5;