import logo from './logo.svg';

import feather from 'feather-icons';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Link } from 'react-router-dom';

import { BiSolidLeftArrow } from 'react-icons/bi';


import token1 from './assets/theme_asset/token/21.png';
import token2 from './assets/theme_asset/token/22.png';
import token3 from './assets/theme_asset/token/23.png';
import token4 from './assets/theme_asset/token/24.png';
import token5 from './assets/theme_asset/token/25.png';
import token6 from './assets/theme_asset/token/26.png';
import token7 from './assets/theme_asset/token/27.png';
import token8 from './assets/theme_asset/token/28.png';
import token9 from './assets/theme_asset/token/29.png';
import token10 from './assets/theme_asset/token/30.png';
import color1 from './assets/theme_asset/token/color1.png';
import color2 from './assets/theme_asset/token/color2.png';
import color3 from './assets/theme_asset/token/color3.png';
import deposite1 from './assets/theme_asset/deposit.png'



import './game.css';

import { useEffect } from 'react';

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';






function Deposite_report() {

  const navigate = useNavigate();


  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  



  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data);
     
      console.log(response.data);

      
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const [modalMessage3, setModalMessage] = useState(null);
  useEffect(() => {
    // Replace icons after component is mounted
    const storedMessage = localStorage.getItem('modalMessage3');
      if (storedMessage) {
        setModalMessage(JSON.parse(storedMessage));
        // Remove modal message from local storage after retrieving
        localStorage.removeItem('modalMessage3');
      }

       
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/deposite_report');
    }
    console.log(token)
    feather.replace();

    fetchData();
  }, []);


  
  if (loading) {
    
      <SkeletonLoader />
    
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data == null)
    {
      return (
        <SkeletonLoader showHeader={false} showFooter={false} /> 

       )
    }

   
  
 
    return (


      <body className='dark'>
      <div >
          

          <div className="auth-header-1" style={{height:'40px'}}>
          <div className="row align-items-center">
  <div className="col-1">
    <Link to="/profile" style={{ textDecoration: 'none', marginLeft:'5px' }}>
    <BiSolidLeftArrow style={{ color: 'white' }} />
    </Link>
  </div>
  <div className='col-4'></div>
  <div className="col-3 p-1 ">
  <h2 style={{marginTop:'5px'}}>BBNPLAY</h2>

  </div>
</div>
      </div>
      
    <section><br/>
  <div className="custom-container">
    <div className="title" >
        <h2 >Deposite Report</h2>
    </div>

    {data.deposite_report && data.deposite_report.length > 0 && (
  <section >
  
  <div className="custom-container">
     
      {data.deposite_report.map((deposite, index) => (
        <div className="col-12">
        <div className="transaction-box">
          <a href="#" className="d-flex gap-3">
            <div className="transaction-image">
              <img
                className="img-fluid "
                src={deposite1}
                alt="p1"
              />
            </div>
            <div className="transaction-details">
              <div className="transaction-name">
                <h5>{deposite.reason}</h5>
                <h3 className="success-color">
                  ${deposite.amount}
                </h3>
              </div>
             
            </div>
          </a>
        </div>
      </div>
      ))}
    </div>
  </section>
)}
          
       </div><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
       </section>
       </div>
       </body>
  
  );
  }

  export default Deposite_report;
