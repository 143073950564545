import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Login from './login';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";



import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

import Modal from "./modal";
import SkeletonLoader from './SkeletonLoader';






function Account() {

  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});

  


  const fetchData = async () => {
    try {
      
      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data);
      
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const name = e.target.name.value;
      const id = e.target.id.value;
      const mobile = e.target.mobile.value;
      const email = e.target.email.value;
      const upiid = e.target.upiid.value;


      console.log('FormData to send:', name ,id, mobile, email, upiid); // Log the formDataToSend
      const response = await axios.post(`${apiBaseUrl}/update`, {
        name,
        id,
        mobile,
        email,
        upiid
      }, {
        headers: {
          'Content-Type': 'application/json', // Set content type to application/json for JSON data
        },
      });

      setModalMessage({
        title: 'User updated Successfully',
        content: 'You have upaded the information successfully',
      });
      setShowModal(true);
      console.log('User updated successfully:', response.data);
      navigate('/account'); // Redirect the user to the homepage after successful registration
      // You can add any further actions here, like redirecting the user or showing a success message
    } catch (error) {
      console.error('Error updating user:', error);
      setModalMessage({
        title: 'Error',
        content: 'Failed to update the information. Please enter vaild data or try again later.',
      });
      setShowModal(true);
      // Handle error, show error message, etc.
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };
 
  useEffect(() => {
    // Replace icons after component is mounted
    
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/account');
    }
    console.log(token)
    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Navigate to the login page
    navigate('/login');
  };
  
  if (loading) {
    return <SkeletonLoader showHeader={false} showFooter={false} /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }


  
  return (

    <body className='dark'>
    <div>
     {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
       
       <div className="auth-header">
       <div>
            <Link to="/profile" style={{ textDecoration: 'none' , marginTop: '20px' , paddingLeft:'10px'}}>
              <button style={{ 
                padding: '8px 16px',
                backgroundColor: 'transparent',
                color: 'white',
                border: 'none',
                borderRadius: '4px',
                cursor: 'pointer',
                
                transition: 'background-color 0.3s',
              }}>X</button>
            </Link>
          </div>
    
    <div className="auth-content">
      <div>
        <h2>Welcome</h2>
        <h4 className="p-0">Update the information</h4>
      </div>
    </div>
  </div>
  {/* header end */}
  {/* login section start */}
  <form className="auth-form" onSubmit={handleSubmit}>
    <div className="custom-container">
    <div className="form-group">
  <label htmlFor="name" className="form-label">
    Full name
  </label>
  <div className="form-input">
    <input
      type="text"
      className="form-control"
      id="name"
      name="name"
      defaultValue={data.user.name}
      placeholder="Enter your name"
    />
    <input
      type="hidden"
      className="form-control"
      id="id"
      name="id"
      defaultValue={data.user.id}
      placeholder="Enter your ID"
    />
  </div>
</div>

<div className="form-group">
  <label htmlFor="mobile" className="form-label">
    Mobile
  </label>
  <div className="form-input">
    <input
      type="number"
      className="form-control"
      id="mobile"
      name="mobile"
      placeholder="Enter your Mobile"
      defaultValue={data.user.mobile}
    />
  </div>
</div>

<div className="form-group">
  <label htmlFor="email" className="form-label">
    Email ID
  </label>
  <div className="form-input">
    <input
      type="email"
      className="form-control"
      id="email"
      name="email"
      placeholder="Enter Your Email ID"
      defaultValue={data.user.email}
    />
  </div>
</div>

<div className="form-group">
  <label htmlFor="upiid" className="form-label">
    UPI ID
  </label>
  <div className="form-input">
    <input
      type="text"
      className="form-control"
      id="upiid"
      name="upiid"
      placeholder="Enter Your UPI ID"
      defaultValue={data.user.upi_id}
    />
  </div>
</div>
     
     
      <button type='submit' className="btn theme-btn w-100">
        Update
      </button>
      
    </div><br /><br /><br /><br /><br />
  </form>
       
        
      </div>
      </body>
  );
}

 


export default Account;
