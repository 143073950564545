import logo from './logo.svg';
import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import Modal from "./modal";
import { Link } from 'react-router-dom'; // Import the Link component
import auth6 from './assets/theme_asset/authentication/6.svg';
import { useEffect } from 'react';


// Assuming you're using React Router and have a route set up for blog details

// Your other imports...

const MySwal = withReactContent(Swal);

function Register() {
  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState({});
  
  // Function component definition

  const [formData, setFormData] = useState({
    name: '',
    DOB: '',
    email: '',
    mobile: '',
    refferalid: '',
    upiid: '',
    password: '',
    image: '',
  });

  const fetchData = async (refferalid) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/userpageapi`);
      const userData = response.data.user;
  
      let found = false;
  
      // Loop through each user object in the userData array
      userData.forEach(user => {
        // Check if the user's email matches the referral id
        if (user.email === refferalid) {
          found = true;
        }
      });
  
      if (found) {
        MySwal.fire({
          icon: 'success',
          title: 'Referral ID Found',
          text: 'This referral ID exists in the database!',
        });
      } else {
        MySwal.fire({
          icon: 'error',
          title: 'Referral ID Not Found',
          text: 'This referral ID does not exist in the database!',
        });
      }
    } catch (error) {
      console.error('Error checking referral ID:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to check referral ID. Please try again later.',
      });
    }
  };
  

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    console.log('Name:', name);
    console.log('Value:', value);
    console.log('Files:', files); // Check if files contain the image data
    const image = name === 'image' ? files[0] : null;
    console.log('Image:', image); // Check if the image is correctly assigned
    setFormData({ ...formData, [name]: value, image });
  
    if (name === 'refferalid') { // Check if the changed input is the referral ID
      console.log('Referral ID changed. Value:', value); // Log when referral ID changes
      fetchData(value); // Call fetchData only when referral ID changes
    }
  };
  
  
  

    // Your JSX code...
  
  

  // Function to submit the form data
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      localStorage.setItem('modalMessage4', JSON.stringify({
        title: 'User registered successfully',
        content: 'You have registered and logged in successfully',
      }));
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      const response = await axios.post(`${apiBaseUrl}/register`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set content type to multipart/form-data for file upload
        },
      });
      const token = response.data.token;
      const id = response.data.user_id;
      localStorage.setItem('token', token);
      localStorage.setItem('id', id);
      navigate('/'); // Redirect the user to the homepage after successful registration
    } catch (error) {
      console.error('Error registering user:', error);
      setModalMessage({
        title: 'Error',
        content: 'Failed to register. Please try again later or check the information you filled.',
      });
      setShowModal(true);
    }
  };

  // Function to close the modal
  const closeModal = () => {
    setShowModal(false);
  };

  // Function to check the referral ID against the database
  
  
  
  return (

    <body className="dark">
    
    <div>
       {showModal && <Modal message={modalMessage} closeModal={closeModal} />}
       <div className="auth-header">
    <Link to="/login">
    <button style={{
                  padding: '8px 16px',
                  color: 'white',
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}>X</button>
    </Link>
    <div className="auth-content">
      <div>
        <h2>Welcome</h2>
        <h4 className="p-0">Fill up the form</h4>
      </div>
    </div>
  </div>
  {/* header end */}
  {/* login section start */}
  <form className="auth-form" onSubmit={handleSubmit}>
    <div className="custom-container">
      <div className="form-group">
        <label htmlFor="name" className="form-label">
          Full name
        </label>
        <div className="form-input">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            placeholder="Enter your name"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="DOB" className="form-label">
          Date of Birth
        </label>
        <div className="form-input">
          <input
            type="date"
            className="form-control"
            id="DOB"
            name="DOB"
            placeholder="Enter your DOB"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="Mobile" className="form-label">
          Mobile
        </label>
        <div className="form-input">
          <input
            type="number"
            className="form-control"
            id="mobile"
            name="mobile"
            placeholder="Enter your Mobile"
            onChange={handleChange}
          />
        </div>
      </div>
      {/* <div className="form-group">
        <label htmlFor="image" className="form-label">
          Profile Image
        </label>
        <div className="form-input">
          <input
            type="file"
            className="form-control"
            id="image"
            name ="image"
            onChange={handleChange}
          />
        </div>
      </div> */}
      <div className="form-group">
        <label htmlFor="email" className="form-label">
          Email id
        </label>
        <div className="form-input">
          <input
            type="email"
            className="form-control"
            id="email"
            name = "email"
            placeholder="Enter Your Email"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="Refferal ID" className="form-label">
          Refferal ID
        </label>
        <div className="form-input">
          <input
            type="text"
            className="form-control"
            id="refferalid"
            name = "refferalid"
            placeholder="Enter Your Refferal Id"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="UPI ID" className="form-label">
          UPI ID
        </label>
        <div className="form-input">
          <input
            type="text"
            className="form-control"
            id="upiid"
            name = "upiid"
            placeholder="Enter Your Upi Id"
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="newpin" className="form-label">
          Enter new Password
        </label>
        <div className="form-input">
          <input
            type="text"
            className="form-control"
            id="password"
            name="password"
            placeholder="Enter Password"
            onChange={handleChange}
          />
        </div>
      </div>
      {/* <div className="form-group">
        <label htmlFor="confirmpassword" className="form-label">
          Re-enter new Password
        </label>
        <div className="form-input">
          <input
            type="number"
            className="form-control"
            id="confirmpin"
            placeholder="Re-enter Password"
          />
        </div>
      </div> */}
      <div className="remember-option mt-3">
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            defaultValue=""
            id="flexCheckDefault"
          />
          <label className="form-check-label" htmlFor="flexCheckDefault">
            I agree to all terms &amp; condition
          </label>
        </div>
      </div>
      <button type='submit' className="btn theme-btn w-100">
        Sign up
      </button>
      <h4 className="signup">
        Already have an account ?<Link to="/login"> Sign in</Link>
      </h4>
    </div>
  </form>
       
        
      </div>
      </body>
  );
}

export default Register;
