import logo from './logo.svg';

import feather from 'feather-icons';
import Footer from './footer';
import Sidebar from './Sidebar';

import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Link } from 'react-router-dom';

// Assuming you're using React Router and have a route set up for blog details






import favicon from './assets/theme_asset/logo/favicon.png';

import logo1 from './assets/theme_asset/logo/logo.png';

import logo2 from './assets/theme_asset/svg/logo1.svg';

import auth_bg from './assets/theme_asset/background/auth-bg.jpg';

import arrow from './assets/theme_asset/svg/arrow.svg';

import arrow_down_right from './assets/theme_asset/svg/arrow-down-right.svg';
import arrow_up_right from './assets/theme_asset/svg/arrow-up-right.svg';

import p1 from './assets/theme_asset/person/p1.png';
import p2 from './assets/theme_asset/person/p2.png';
import p3 from './assets/theme_asset/person/p3.png';
import p4 from './assets/theme_asset/person/p4.png';
import p5 from './assets/theme_asset/person/p5.png';
import ellipse from './assets/theme_asset/svg/ellipse.svg';

import a1 from './assets/theme_asset/svg/1.svg';
import a2 from './assets/theme_asset/svg/2.svg';
import a3 from './assets/theme_asset/svg/3.svg';
import a4 from './assets/theme_asset/svg/4.svg';
import a5 from './assets/theme_asset/svg/5.svg';

import a6 from './assets/theme_asset/svg/6.svg';
import a7 from './assets/theme_asset/svg/7.svg';
import a8 from './assets/theme_asset/svg/8.svg';
import a9 from './assets/theme_asset/svg/9.svg';
import a10 from './assets/theme_asset/svg/10.svg';
import a11 from './assets/theme_asset/svg/11.svg';

import card_chip from './assets/theme_asset/svg/card-chip.svg';

import img1 from './assets/theme_asset/1.jpg';

import contact from './assets/theme_asset/svg/contact.svg';



import auth6 from './assets/theme_asset/authentication/6.svg';

import img2 from './assets/theme_asset/2.jpg';
import img3 from './assets/theme_asset/3.jpg';
import img4 from './assets/theme_asset/4.jpg';
import l49 from './assets/theme_asset/logo/48.png';

import search from './assets/search (1).png';


import './App.css';

import scan from './assets/theme_asset/svg/scan.svg';
import { useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import SkeletonLoader from './SkeletonLoader';







function Blog() {
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const [recMetrics,setRecMetrics] = useState([0,0,0])
  







      

     // console.log(apiBaseUrl);



  const fetchData = async () => {
    try {
      

      const id = localStorage.getItem('id');
      const response = await axios.get(`${apiBaseUrl}/homepageapi/${id}`);
      setData(response.data);
     
      console.log(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  

  useEffect(() => {
    // Replace icons after component is mounted
    
    
    const token = localStorage.getItem('token');
    if(token == null){
      
        navigate('/login');
      
    }else{
      navigate('/blog');
    }
    console.log(token)
    feather.replace();

    fetchData();
  }, []);

  if (loading) {
    return  <SkeletonLoader /> ;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if(data == null)
    {
      return (
          <SkeletonLoader showHeader={false} showFooter={true} /> 
       )
    }

  
  return (
<body className='dark'>
    
    <div>

<div className="auth-header" style={{height:'150px'}}>
      <div>
              <Link to="/" style={{ textDecoration: 'none' }}>
                <button style={{
                  padding: '8px 16px',
                  color: 'white',
                  backgroundColor: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                }}>X</button>
              </Link>
            </div>
        
        <div className="auth-content" style={{justifyContent:'center'}}>
          <div>
            <center>
            <h2>News / Articles Section</h2>
            <h4 className="p-0">Latest updates you should know</h4>
            </center></div>
        </div>
      </div>
       
        {/* monthly statistics section end */}
        {/* news-update section starts */}
        <section className="section-b-space" style={{borderRadius:'15px'}}>
          <div className="custom-container">
         
            <div className="row gy-3">
            {data.latest_content_bytes.map((content_byte, index) => (
            <><div style={{padding:'20px', color:'white'}}><Link to={`/blog_details/${content_byte.id}`} style={{textDecoration:'none'}}>
              <img className="img-fluid" style={{width:'100%'}} src={`${apiImageUrl}/blog_image/${content_byte.blog_image}`} alt="img1" />
              <div>
                <h3 style={{paddingTop:'10px',color: 'white'}}>{content_byte.blog_title}</h3>
                <div style={{display:'flex', justifyContent:'space-between',color: 'white'}}>
                <p>Date: {content_byte.date}</p>
                <p>Author: {content_byte.author}</p>
              </div></div>
              </Link></div></>
          ))}
           
             
            </div>
          </div>
        </section>
        {/* news-update section end */}
        {/* panel-space start */}
        <section className="panel-space" />
        {/* panel-space end */}
        {/* bottom navbar start */}
       
        {/* bottom navbar end */}
        {/* add money modal start */}
        <Footer/>
     
        
      </div>

      </body>
  );
}

export default Blog;
